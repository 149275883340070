@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: 'Urbanist', 'Gilroy', 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  overflow-x: hidden;
  letter-spacing: 0.04rem;
}

main {
  overflow-x: hidden;
  min-height: 60vh;
}

select,
button {
  cursor: pointer;
}

ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
}

a {
  text-decoration: none;
}

.fz-16px {
  font-size: 16px;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  display: flex;
  gap: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.slick-slider .slick-slide div:first-child {
  height: 100%;
}

.separator-horizontal {
  margin: 24px 0;
  width: 100%;
  height: 1px;
  background-color: var(--mercury);
}

.flex-gap-36 {
  gap: 36px;
}

.nav.nav-tabs {
  border-bottom: 1px solid #E4E4E4;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  text-transform: uppercase;
  border-color: transparent;
  border-bottom: 1px solid rgba(174, 126, 3, 0.6);
  color: var(--pirateCold);
}

.tab-custom .nav-item button {
  padding-right: 40px;
  padding-left: 40px;
  font-size: 16px;
  font-family: inherit;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
  border-bottom: 1px solid rgba(174, 126, 3, 0.6);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  text-transform: uppercase;
  color: var(--mineShaftS);
}

.cursor-pointer {
  cursor: pointer;
}

.form-check-input:checked {
  background-color: var(--pirateCold);
  border-color: var(--pirateCold);
}

.form-check-input:focus {
  border-color: var(--pirateCold);
  outline: 0;
  box-shadow: unset;
}

a:hover {
  color: var(--pirateCold);
}

.pl-0 {
  padding-left: 0;
}

.pr-5px {
  padding-right: 5px;
}

button[disabled] {
  opacity: 0.6;
  pointer-events: none;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.line-separator {
  width: 100%;
  height: 1px;
  background-color: rgba(174, 126, 3, 0.6);
}

.ta-c {
  text-align: center;
}

.ml-12 {
  margin-left: 12px;
}

.mt-80px {
  margin-top: 80px;
}
.mt-90px {
  margin-top: 90px;
}

.mb-20px {
  margin-bottom: 20px;
}

.h-100 {
  height: 100%;
}

.modal.transparent .modal-content {
  background-color: transparent;
  border: none;
  /*height: 100%;*/
}

.modal.transparent .modal-content .btn-close {
  /*border: 2px solid rgba(3, 3, 3, 0.3);*/
  background: none !important;
}

.modal.transparent .modal-content .btn-close:hover {
  opacity: 1;
}

.modal.transparent .modal-content .btn-close:before, .modal.transparent .modal-content .btn-close:after {
  position: absolute;
  right: 15px;
  top: 0;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #fff;
}

.modal.transparent .modal-content .btn-close:focus {
  box-shadow: none;
}

.modal.transparent .modal-content .btn-close:before {
  transform: rotate(45deg);
}

.modal.transparent .modal-content .btn-close:after {
  transform: rotate(-45deg);
}

.modal.transparent .modal-dialog {
  width: auto;
  max-width: fit-content;
  height: auto;
  max-height: fit-content;
  min-height: fit-content;
  top: 50%;
  transform: translateY(-50%);
}

.modal-backdrop.show {
  opacity: .8 !important;
}

.modalPreviewCollection .btn-close {
  position: absolute;
  top: -30px;
  right: -30px;
  background: none !important;
}

.modalPreviewCollection .btn-close:hover {
  opacity: 1;
}

.modalPreviewCollection .btn-close:before, .modalPreviewCollection .btn-close:after {
  position: absolute;
  right: 15px;
  top: 0;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #fff;
}

.modalPreviewCollection .btn-close:before {
  transform: rotate(45deg);
}

.modalPreviewCollection .btn-close:after {
  transform: rotate(-45deg);
}

.modalPreviewCollection .modal-header,
.modalPreviewCollection .modal-body,
.modalPreviewCollection .modal-footer {
  padding: 0;
}

  /*.modal.transparent .btn-close{
    position: fixed;
    right: 20px;
    top: 20px;
  }*/
/*.modal.transparent .modal-dialog{
  width: 100%;
  margin: 0;
  max-width: 100%;
  height: 100%;
}*/

.modalClean .btn-close {
  position: absolute;
  top: -30px;
  right: -30px;
  background: none !important;
}

.modalClean .btn-close:hover {
  opacity: 1;
}

.modalClean .btn-close:before, .modalClean .btn-close:after {
  position: absolute;
  right: 15px;
  top: 0;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #fff;
}

.modalClean .btn-close:before {
  transform: rotate(45deg);
}

.modalClean .btn-close:after {
  transform: rotate(-45deg);
}

.modalClean .modal-header,
.modalClean .modal-body,
.modalClean .modal-footer {
  padding: 0;
}

.gap-5px {
  gap: 5px;
}

.gap-15px {
  gap: 15px;
}

.setOpacity {
  opacity: 0.7;
}

.btn-filter {
  padding: 10px 18px;
  background-color: var(--white);
  border: 1px solid var(--pirateCold);
  color: var(--pirateCold);
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 14px;
  transition: background 0.4s ease;
}

.btn-filter:hover,
.btn-filter:focus {
  background-color: rgba(174, 126, 3, 0.2);
}

.fz-15 {
  font-size: 15px;
}

.fz-18 {
  font-size: 18px;
}

.color-steel-gray {
  color: var(--steelGray);
}

.ff-urbanist {
  font-family: 'Urbanist', sans-serif;
}

.tt-u {
  text-transform: uppercase;
}

.fw-700 {
  font-weight: 700;
}

.btn-border-b {
  border-bottom: 1px solid;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.btn-explore-more {
  padding-left: 0;
  padding-right: 0;
  font-family: 'Urbanist', sans-serif;
  color: var(--steelGray);
  text-transform: uppercase;
  font-weight: 700;
  border-bottom: 1px solid;
  border-radius: 0;
}

.errorMessage {
  margin: 6px 0 0 14px;
  font-weight: 500;
  font-size: 12px;
  color: var(--thunderbird);
}

/* input range */

/*input[type='range'] {*/
/*  outline: none;*/
/*  transition: opacity 0.2s ease;*/
/*  accent-color: var(--pirateCold);*/
/*}*/

/*input[type="range"]:active {*/
/*  cursor: grabbing;*/
/*}*/

/*input[type="range"],*/
/*input[type="range"]::-webkit-slider-runnable-track,*/
/*input[type="range"]::-webkit-slider-thumb {*/
/*  transition: all ease 0.1s;*/
/*}*/

/*input[type="range"]:hover::-webkit-slider-thumb {*/
/*  cursor: grab;*/
/*}*/

/*input[type="range"]:active::-webkit-slider-thumb {*/
/*  cursor: grabbing;*/
/*}*/

/*input[type="range"]:disabled::-webkit-slider-thumb {*/
/*  cursor: not-allowed;*/
/*}*/

input[type="range"] {
  color: #ef233c;
  --thumb-height: 1.125em;
  --track-height: 0.125em;
  --track-color: rgba(0, 0, 0, 0.2);
  --brightness-hover: 180%;
  --brightness-down: 80%;
  --clip-edges: 0.125em;
}

/* === range commons === */
input[type="range"] {
  position: relative;
  overflow: hidden;
}

input[type="range"].win10-thumb {
  --thumb-height: 1.375em;
  --thumb-width: 0.5em;
  --clip-edges: 0.0125em;
}

input[type="range"]:active {
  cursor: grabbing;
}

input[type="range"]:disabled {
  filter: grayscale(1);
  opacity: 0.3;
  cursor: not-allowed;
}

/* === WebKit specific styles === */
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  position: relative;
}

input[type="range"]::-webkit-slider-thumb {
  --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
  --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
  --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
  --clip-further: calc(100% + 1px);
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
  100vmax var(--pirateCold);

  width: var(--thumb-width, var(--thumb-height));
  background: linear-gradient(var(--pirateCold) 0 0) scroll no-repeat left center /
    50% calc(var(--track-height) + 1px);
  background-color: var(--pirateCold);
  box-shadow: var(--box-fill);
  border-radius: var(--thumb-width, var(--thumb-height));

  filter: brightness(100%);
  clip-path: polygon(
    100% -1px,
    var(--clip-edges) -1px,
    0 var(--clip-top),
    -100vmax var(--clip-top),
    -100vmax var(--clip-bottom),
    0 var(--clip-bottom),
    var(--clip-edges) 100%,
    var(--clip-further) var(--clip-further)
  );
}

input[type="range"]:hover::-webkit-slider-thumb {
  cursor: grab;
}

input[type="range"]:active::-webkit-slider-thumb {
  cursor: grabbing;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
    100% calc(var(--track-height) + 1px);
}

input[type="range"]:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}

/* === Firefox specific styles === */
input[type="range"],
input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb {
  appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
  background: var(--pirateCold);
}

input[type="range"]::-moz-range-thumb {
  background: var(--pirateCold);
  border: 0;
  width: var(--thumb-width, var(--thumb-height));
  border-radius: var(--thumb-width, var(--thumb-height));
  cursor: grab;
}

input[type="range"]:active::-moz-range-thumb {
  cursor: grabbing;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  background: var(--track-color);
}

input[type="range"]::-moz-range-progress {
  appearance: none;
  background: var(--pirateCold);
  transition-delay: 30ms;
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-progress {
  height: calc(var(--track-height) + 1px);
  border-radius: var(--track-height);
}

input[type="range"]:disabled::-moz-range-thumb {
  cursor: not-allowed;
}

/* end input range */

.ml-10px {
  margin-left: 10px;
}

.min-w-100px {
  min-width: 100px;
}

.min-w-150px {
  min-width: 150px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 99%;
  white-space: nowrap;
}

.cursor-default {
  cursor: default !important;
}

.text-doveGrayS {
  color: var(--doveGrayS)
}

.breadcrumb {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin: 0 auto;
  width: 100%;
  font-family: 'Bebas Neue', 'Urbanist', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: var(--white);
}

.breadcrumb a {
  color: rgba(255, 255, 255, 0.7);
}

.breadcrumb-black {
  color: var(--black);
}

.breadcrumb-black a {
  color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 500px) {
  .flex-wrap-500px {
    flex-wrap: wrap;
  }
}

.cardWrapBig {
  position: relative;
  width: 100%;
  max-width: 690px;
  background: var(--white);
  border-radius: 4px;
  border-bottom: 1px solid #EBEBEB;
  transition: all 0.4s ease;
}

.cardWrapBig:hover {
  box-shadow: 0 20px 15px rgba(174, 126, 3, 0.05);
}

.cardWrapBig__coverImage {
  min-height: 186px;
  max-height: 186px;
  border-radius: 4px 4px 30px 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cardWrapBig__imageWrap {
  margin: -65px auto 0 auto;
  padding: 6px;
  width: 130px;
  height: 130px;
  background-color: #fff;
  border-radius: 100%;
}

.cardWrapBig__image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.cardWrapBig__collectionName {
  margin-bottom: 20px;
  font-family: 'Bebas Neue', 'Urbanist', sans-serif;
  color: var(--black);
  font-size: 48px;
  text-align: center;
  text-transform: uppercase;
}

.cardWrapBig__actionsWrap {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
}

.cardWrapBig__creatorName {
  margin-bottom: 20px;
  font-family: 'Bebas Neue', 'Urbanist', sans-serif;
  font-size: 48px;
  text-align: center;
  text-transform: uppercase;
  color: var(--black)
}

.cardWrapBig__description {
  margin-bottom: 20px;
  font-family: 'Urbanist', sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #1F1F2C;
}

.cardWrapBig__controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardWrapBig__userInfo {
  margin-right: 20px;
}

.cardWrapBig__content {
  margin-top: 10px;
  padding: 16px;
}

.gap-20 {
  gap: 20px;
}

.pointer-none {
  pointer-events: none;
}

@media (max-width: 500px) {
  .lazyload-wrapper {
    width: 100%;
  }

  .cardWrapBig__collectionName {
    font-size: 28px;
  }

  .cardWrapBig__description {
    margin-bottom: 30px;
    font-size: 14px;
  }

  .cardWrapBig__controls {
    flex-direction: column;
    gap: 20px;
  }
}

.m-0-a {
  margin: 0 auto;
}

.flex-dir-column {
  flex-direction: column;
}

.carousel-previews-token {
  width: 100%;
  height: 100%;
  max-width: 480px;
}

.modal-max-w-500 .modal-dialog {
  max-width: 500px !important;
}

.gap-15 {
  gap: 15px;
}
