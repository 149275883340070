.container{
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: var(--mineShaftS);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px;
}