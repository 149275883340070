.inputSubscribe {
  width: 100%;
}

.inputSubscribe > div:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.inputSubscribe input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--alabasterapprox);
}

.inputSubscribe input::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: var(--doveGrayS);
}

.btnSubscribe {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background: var(--pirateCold);
  border-radius: 0px 30px 30px 0px;
  border: none;
}
