.identificationWrap {
  display: flex;
  align-items: center;
}

.btnCopy {
  margin-left: 4px;
  padding: 0;
  border: none;
  background-color: transparent;
}

.artistId {
  margin: 0 5px 0 0;
  color: var(--doveGrayS);
  transition: color 0.4s ease;
}

.colorCopySuccess {
  color: var(--pirateCold);
}