.collectionTabWrap ul {
  margin-top: 10px;
  border: none !important;
}

.tabsWrap ul li button {
  text-transform: uppercase;
  letter-spacing: 0.04rem;
}

.tokenWrap {
  padding: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1400px) {
  .tokenWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    overflow-x: auto;
  }
}

@media (max-width: 1000px) {
  .tokenWrap {
    display: flex;
    gap: 20px;
    overflow-x: auto;
    flex-wrap: nowrap;
  }
}

.cardCreate {
  width: 100%;
  max-width: 300px;
  padding: 80px 60px;
  background: #FFFFFF;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.05), 0px 30px 50px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
}

.cardCreate h4 {
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: var(--mineShaftS);
}

.cardCreate p {
  margin-bottom: 50px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: var(--doveGrayS);
}

.cardCreate button {
  width: 100%;
}
