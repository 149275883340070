.card {
  font-family: 'Urbanist', sans-serif;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.4s ease;
  letter-spacing: 0.04rem;
  border-radius: 16px;
  overflow: hidden;
}

.card.onlyViewed {
  box-shadow: none;
}

.imageStyle {
  will-change: filter;
}

.cardImage {
  position: relative;
  height: 100%;
  max-height: 390px;
}

.cardDetail {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
}

.cardDetail .count,
.cardDetail .daysInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  font-weight: 700;
  font-size: 14px;
  color: var(--white);
  background: var(--bandicoot);
  border-radius: 4px;
}

.cardDetail .daysInfo {
  margin-left: auto;
}

.cardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.cardFooter .cardInfo {
  max-width: 100%;
}

.cardFooter .cardInfo .tokenName {
  height: unset !important;
  font-weight: 700;
  font-size: 18px;
  color: var(--steelGray);
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 350px;
  overflow: hidden;
}

.cardFooter .cardInfo .authorName {
  font-weight: 400;
  font-size: 15px;
  color: var(--steelGray);
}

.tokenType {
  font-weight: 400;
  font-size: 15px;
  color: var(--manatee);
}

.likes {
  margin-right: 16px;
}

.imageStyle {
  width: 100%;
  height: 100%;
  object-fit: contain !important;
  transition: filter 0.4s ease;
}

.button {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  white-space: nowrap;
}

.buttonDetails {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 13px 16px 10px 16px;
  transform: translate(-50%, -50%);
  font-weight: 500;
  font-size: 15px;
  transition: all 0.4s ease;
}

.btnTransfer {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 10px;
  border-radius: 4px;
}

.timerWrap {
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
}

.buttonSecondControls {
  padding: 8px 30px;
  font-size: 14px;
  font-weight: normal;
  white-space: nowrap;
}

.card.cardSmall {
  width: 100%;
  max-width: 297px;
}

.card.cardSmall .imageStyle {
  min-height: 297px;
  max-height: 297px;
  object-fit: contain;
  background-position: center;
}

.cardFooter .cardControls {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.card.cardSmall .cardFooter .cardControls {
  border-top: 1px solid var(--eb-gray);
  margin-top: 10px;
}

.card .cardFooter {
  column-gap: 20px;
}

.card.cardSmall .cardFooter {
  flex-wrap: wrap;
}

.card.cardSmall .cardControls {
  justify-content: space-between;
  width: 100%;
}

.card.cardSmall .likes {
  margin-right: 0;
}

.card.cardSmall .tokenName {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 270px;
  overflow: hidden;
}

.priceText {
  font-family: 'Gilroy', sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: var(--pirateCold);
}

.toggleTitle {
  font-weight: 600;
  font-size: 16px;
  color: var(--mineShaftS);
}

.customFormCheck {
  padding: 0;
  min-height: auto;
}

.onlyViewed .cardFooter {
  padding: 16px 0;
}

.onlyViewed .cardControls {
  display: none;
}

.puOnMarketplaceWrap {
  padding: 0 16px 10px 16px;
}

.priceWrap {
  margin-right: 20px;
}

.priceWrap span {
  font-weight: 400;
  font-size: 13px;
  color: var(--manatee);
}

.priceWrap p {
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: var(--steelGray);
}

@media (min-width: 900px) {
  .card.isHover:hover {
    box-shadow: 0 3px 24px rgba(13, 71, 161, 0.2);
  }
}

@media (max-width: 991px) {
  .card.cardSmall {
    min-width: 292px;
  }

  .card.cardSmall {
    max-width: unset;
  }

  .cardFooter .cardInfo .tokenName {
    max-width: 250px;
  }
}

@media (max-width: 600px) {
  .cardFooter {
    flex-direction: column;
    align-items: flex-start;
  }

  .cardFooter .cardInfo .tokenName {
    max-width: 200px;
  }
}
