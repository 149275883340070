.modal button {
  padding: 6px 12px;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

.modal button:not(:last-of-type) {
  margin-right: 20px;
}

.modalTitle {
  font-size: 24px;
  font-weight: 500;
  color: var(--mineShaft)
}

.borderNone {
  border: none;
}