.inputWrap {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  background: var(--white);
}

.inputDefault {
  border-radius: 4px;
  background: var(--wildSand);
}

.inputDefault input,
.inputDefault textarea {
  margin-top: 10px;
  padding: 15px 10px;
  width: 100%;
  min-height: 44px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  outline: none;
  border: none;
  background-color: var(--wildSand);
}

.inputRound {
  border: 1px solid rgba(174, 126, 3, 0.6);
  border-radius: 30px;
}

.inputRound input,
.inputRound textarea {
  padding: 5px 10px;
  width: 100%;
  min-height: 44px;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  border: none;
  color: var(--mineShaftS);
  background-color: var(--white);
  border-radius: 30px;
}

.inputView:focus {
  color: unset;
  background-color: unset;
  border-color: unset;
  outline: 0;
  box-shadow: unset;
}

.inputView::placeholder {
  font-size: 14px;
  font-weight: 500;
  color: var(--doveGray);
}

.icon {
  width: 24px;
  height: 24px;
  margin: 0 0 0 10px;
}

.absPlaceholder {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 95%;
  transform: translateY(-50%);
  font-size: 16px;
  transition: all 0.4s ease;
  will-change: font-size;
  color: var(--doveGray);
  background: var(--wildSand);
  pointer-events: none;
}

.inputView:focus + .absPlaceholder {
  top: 15px;
  font-size: 12px;
}

.inputView:not(:placeholder-shown) + .absPlaceholder {
  top: 15px;
  font-size: 12px;
}

.inputView.hidePlaceholder::placeholder {
  color: transparent;
}

.inputBox {
  width: 100%;
  position: relative;
}

.error .inputWrap {
  border: 1px solid var(--thunderbird);
}

.error .absPlaceholder {
  color: var(--thunderbird);
}

.errorMessage {
  margin: 6px 0 0 14px;
  font-weight: 500;
  font-size: 12px;
  color: var(--thunderbird);
}
