.containerWrap {
  width: 100%;
  max-width: 560px;
  margin-bottom: 40px;
}

.title {
  margin: 20px 0 40px 0;
  text-align: center;
}

.contentWrap {
  display: flex;
  justify-content: center;
  gap: 60px;
}

.label {
  font-weight: 600;
  font-size: 16px;
  color: var(--mineShaftS);
  margin-bottom: 20px;
}

.dropzone {
  position: relative;
}

.customDropzone {
  border: 2px dashed rgba(174, 126, 3, 0.6);
  border-radius: 8px;
  padding: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 560px;
}

.uploadForm {
  min-height: 157px;
}

.dropzoneControls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  position: relative;
}

.dropzoneHover{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
  opacity: 0;
  transition: 0.3s all ease;
}

.dropzoneControls .dropzoneHover.dropzoneHoverShow:hover{
  opacity: 1;
  transition: 0.3s all ease;
}

.dropzoneHover p{
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
}

.iFrameStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  object-fit: contain;
}

.iFrameStyle img {
  object-fit: contain;
}

.dropzoneTitle {
  font-weight: 500;
  font-size: 14px;
  color: var(--doveGrayS);
}

.previewWrap {
  width: 100%;
  max-width: 310px;
}

.spinnerWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  border-radius: 8px;
}
