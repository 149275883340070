.cardImage {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 50%;
  min-height: 30vh;
  /*height: calc(100vh - 63px);*/
}

.cardImageWrap {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 640px;
  max-height: 640px;
  display: flex;
  justify-content: center;
}

.cardImage .imageStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cardDetail {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
}

.cardDetail .count, .cardDetail .daysInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  color: var(--black);
  background: var(--white);
  border: 2px solid rgba(174, 126, 3, 0.2);
  border-radius: 4px;
  font-size: 14px;
}

.cardDetail .daysInfo {
  margin-left: auto;
}

.tokenInfo {
  width: 100%;
}

.categoriesList {
  margin-top: 28px;
  display: flex;
  gap: 8px;
}

.creator {
  margin-top: 20px;
}

.creator .title {
  font-weight: 600;
  font-size: 16px;
  color: var(--doveGrayS);
  margin-bottom: 20px;
}

.tabsWrap {
  margin-top: 30px;
}

.tabContainer {
  padding: 24px 24px;
  font-size: 16px;
  color: var(--mineShaftS);
  max-height: 279px;
  overflow-y: auto;
  min-height: 150px;
}

.buttonWrap {
  margin-top: 20px;
}

.button {
  width: 100%;
  max-width: 240px;
  white-space: nowrap;
}

.category {
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  padding: 6px 12px;
  background: var(--alabasterapprox);
  width: fit-content;
}

.line {
  margin: 16px 0;
  width: 100%;
  height: 1px;
  background-color: rgba(174, 126, 3, 0.2);
}

.mainWrap {
  margin-top: 40px;
  font-family: 'Urbanist', sans-serif;
  letter-spacing: 0.04rem;
}

.tokenTitle {
  font-weight: 700;
  font-size: 30px;
  color: var(--steelGray);
}

.counterWrapStyle {
  padding: 6px 14px;
  background-color: var(--alabasterapprox);
  color: var(--steelGray);
  font-weight: 700;
  border-radius: 40px;
}

.priceWrap {
  font-weight: 600;
  font-size: 15px;
  line-height: 1.5rem;
  color: var(--topaz);
}

.priceWrap strong {
  font-weight: 700;
  font-size: 22px;
  color: var(--steelGray);
}

.tokenInformationWrap {
  display: flex;
  gap: 50px;
  padding: 24px;
  width: 100%;
  border-radius: 8px;
  background-color: var(--alabasterapprox);
}

.tokenInformationWrap .infoTitle {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 18px;
  color: var(--black);
}

.tokenInformationWrap ul li:not(:last-of-type) {
  margin-bottom: 15px;
}

.tokenInformationWrap .specList li p {
  line-height: 1;
  white-space: nowrap;
}

.tokenInformationWrap .specList li p:first-of-type {
  font-weight: 600;
  font-size: 15px;
  color: var(--black);
}

.tokenInformationWrap .specList li {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.tokenInformationWrap .specList li p:last-of-type {
  font-weight: 400;
  font-size: 15px;
  color: var(--tundora);
}

.specList span {
  height: 1px;
  width: 100%;
  background-color: var(--alto);
}

.tokenInformationWrap .infoList li p {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 15px;
  line-height: 1;
  color: var(--black);
}

.tokenInformationWrap .infoList li span {
  font-weight: 400;
  font-size: 15px;
  color: var(--tundora);
}

.infoLine {
  height: 1px;
  width: 100%;
  background-color: var(--alto);
}

.timerTitle {
  font-weight: 600;
  font-size: 15px;
  color: #7A798A;
}

@media(max-width: 1200px) {
  .tokenInformationWrap {
    flex-direction: column;
  }
}

@media (max-width: 991px) {
  .mainWrap {
    flex-direction: column;
  }

  .cardImage {
    max-width: 100%;
  }

  .tokenInformationWrap .specList li p {
    white-space: unset;
  }

  .infoLine {
    display: none;
  }
}

@media (max-width: 700px) {
  .tokenInformationWrap .specList li {
    flex-direction: column;
    justify-content: unset;
    align-items: flex-start;
    gap: 5px;
  }

  .artistsWrap {
    flex-direction: column;
    gap: 20px !important;
  }

  .tokenTitle {
    font-size: 20px;
  }
}

.imageStyle{
  object-fit: contain;
  height: 100%;
}

@media(max-width: 991px){
  .imageStyle{
    max-height: 320px;
  }
}

@media(min-width: 992px){
  .imageStyle{
    max-height: 450px;
  }
}
