:root {
  --white: #ffffff;
  --black: #000000;
  --denim: #134fc2;
  --wildSand: #f6f6f6;
  --wildSandS: #f5f5f5;
  --doveGray: #626262;
  --linkWater: #e7eaf8;
  --alabaster: #fafafa;
  --artistBorder: rgba(0, 0, 0, 0.12);
  --limeade: #63b400;
  --mineShaft: #212121;
  --mercury: #e4e4e4;
  --cobalt: #003dac;
  --mineShaftS: #373737;
  --doveGrayS: #737373;
  --alabasterapprox: #f8f8f8;
  --pirateCold: #ae7e03;
  --thunderbird: #c21313;
  --vanillaIce: #f8e6e6;
  --vidaLoca: #56aa1f;
  --cruise: #cffae1;
  --bandicoot: #807F68;
  --steelGray: #1F1F2C;
  --manatee: #8A8AA0;
  --eb-gray: #EBEBEB;
  --topaz: #7A798A;
  --tundora: #424242;
  --alto: #E0E0E0;
  --altoS: #E2E2E2;

  --toastify-color-success: #ae7e03 !important;
}
