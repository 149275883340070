.navLink {
  font-weight: 500;
  font-size: 16px;
  color: var(--black);
  display: inline-block;
  line-height: 1;
}

.navLink img {
  margin-left: 5px;
}

.active {
  color: var(--pirateCold);
}

.navItem:not(:last-of-type) {
  margin-right: 30px;
}
