.profileWrap {
  margin-top: 20px;
}

.bgBlock {
  width: 100%;
  height: 260px;
  background-size: cover;
  background-position: center center;
  border-radius: 0 0 50px 50px;
  transition: filter 0.4s ease;
}

.bgWrap {
  position: relative;
}

.bgWrap:hover .uploadCoverWrap label,
.bgWrap:hover .uploadCoverWrap span {
  opacity: 1;
}

.loadingCover label,
.loadingCover span,
.loadingCover .uploadCoverWrap {
  opacity: 0 !important;
  pointer-events: none !important;
}

.loadingCover .bgBlock {
  filter: blur(5px);
}

.bgWrap:hover .uploadCoverWrap label {
  pointer-events: all;
}

.uploadCoverWrap label {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--pirateCold);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.4s ease;
  pointer-events: none;
  border-radius: 0 0 50px 50px;
}

.spChange {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--white);
  font-size: 14px;
}

.spChange img {
  width: 22px;
  height: 22px;
}

.uploadCoverWrap span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.profileInfoWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -60px;
}

.avatarWrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  background-color: var(--white);
  border-radius: 100%;
}

.avatarWrap img {
  border-radius: 100%;
  object-fit: cover;
}

.profileName {
  font-weight: 400;
  font-size: 34px;
}

.tabsWrap {
  margin-top: 30px;
  background-color: var(--white);
}

.tabsWrap ul {
  background-color: var(--white);
}

.collectionTabWrap ul {
  margin-top: 10px;
  border: none !important;
}

.tabsWrap ul li button {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.04rem;
}

.tabResultContainer {
  background-color: var(--wildSandS);
}

.bioWrap {
  margin: 25px auto 22px auto;
  max-width: 640px;
  text-align: center;
  color: var(--doveGrayS);
}

.buttonFollow {
  display: block;
  margin: 0 auto 22px auto;
  padding: 8px 60px;
}

.uploadAvatarWrap label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  background-color: var(--pirateCold);
  border-radius: 100%;
  cursor: pointer;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.uploadAvatarWrap img {
  border-radius: 0;
}

.avatarWrap:hover .uploadAvatarWrap label {
  opacity: 1;
  pointer-events: all;
}

.avatarSpinnerWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 100%;
  z-index: 1;
}

@media (max-width: 767px) {
  .bgBlock {
    height: 140px;
  }

  .bgWrap {
    padding: 0;
  }
}
