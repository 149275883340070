.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  width: 100%;
  min-height: 350px;
  background-image: url('../../assets/images/collection-head-bg.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 0px 0px 50px 50px;
}

.headerInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  margin: 0 10px;
  width: 100%;
  max-width: 414px;
  min-height: 170px;
  background: rgba(82, 59, 0, 0.75);
  backdrop-filter: blur(40px);
  border-radius: 8px;
}

.title {
  font-family: 'Bebas Neue', 'Urbanist', sans-serif;
  font-weight: 400;
  font-size: 48px;
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
}

.listWrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}

@media (max-width: 992px) {
  .listWrap {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 500px) {
  .title {
    font-size: 38px;
  }
}
