.buttonView {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
  background: rgba(0, 0, 0, 0.87);
  border-radius: 30px;
  outline: none;
  border: none;
  padding: 12px 16px;
  cursor: pointer;
  line-height: 1;
  letter-spacing: 1.4px;
}

.blue {
  background-color: blue;
}

.btnBlue {
  background-color: var(--denim);
  color: var(--white);
}

.redButton {
  color: var(--thunderbird);
  background-color: var(--vanillaIce);
}

.greenButton {
  color: var(--vidaLoca);
  background-color: var(--cruise);
}

.btnGoldFill {
  background-color: var(--pirateCold);
  color: var(--white);
}

.btnWhite {
  background-color: var(--white);
  color: var(--denim);
  text-transform: uppercase;
}

.btnGray {
  background-color: var(--white);
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
}

.btnWhiteGray {
  background-color: var(--white);
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  text-transform: uppercase;
}

.btnSelectGray {
  background-color: #f6f6f6;
  color: var(--mineShaftS);
  font-weight: 500;
}

.btnDarkGray {
  background-color: rgba(0, 0, 0, 0.54);
  color: var(--white);
  font-weight: 500;
}

.btnSelect {
  background-color: var(--white);
  color: var(--pirateCold);
  font-size: 14px;
  font-weight: normal;
  border: 1px solid var(--pirateCold);
  text-transform: uppercase;
}

.btnPrimary {
  background-color: var(--linkWater);
  color: var(--denim);
}

.btnGold {
  background-color: transparent;
  color: var(--pirateCold);
}

.goldBordered {
  color: var(--pirateCold);
  background: var(--white);
  outline: none;
  border: 1px solid var(--pirateCold);
}

.dark {
  color: var(--black);
  background: var(--white);
  outline: none;
  border: 1px solid var(--black);
}

.btnIcon {
  display: flex;
  align-items: center;
}

.btnIcon .btnText {
  margin-left: 5px;
}

.icon {
  min-width: 20px;
  min-height: 20px;
  display: block;
}

.backButton {
  background: transparent;
  outline: none;
  border: none;
}

.backButton .icon {
  min-width: 36px;
  min-height: 36px;
  background-size: contain;
}
