.listWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  overflow: auto;
  padding: 40px;
}

.createCollectionCard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;
  width: 100%;
  max-width: 290px;
  background: var(--white);
  box-shadow: 0 15px 20px rgb(0 0 0 / 5%), 0 30px 50px rgb(0 0 0 / 3%);
  border-radius: 4px;
}

.titleCreate {
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
}

@media (max-width: 500px) {
  .createCollectionCard {
    padding: 40px 20px;
  }
}
