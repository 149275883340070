.container {
  padding: 40px 72px;
  display: flex;
  align-items: start;
  gap: 60px;
  min-height: 1000px;
  justify-content: center;
}

.containerWrap {
  width: 100%;
  max-width: 560px;
}

.previewCreateWrap {
  display: flex;
  align-items: start;
  justify-content: start;
}

.previewWrap {
  min-width: 297px;
  margin-top: 120px;
}

.title {
  font-weight: 600;
  font-size: 34px;
  text-align: center;
  color: var(--mineShaftS);
  margin-bottom: 40px;
}

.dropzone {
  position: relative;
}

.label {
  font-weight: 600;
  font-size: 16px;
  color: var(--mineShaftS);
  margin-bottom: 20px;
}

.customDropzone {
  border: 2px dashed rgba(174, 126, 3, 0.6);
  border-radius: 8px;
  padding: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 560px;
}

.dropzoneControls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  position: relative;
}

.dropzoneHover{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
  opacity: 0;
  transition: 0.3s all ease;
}

.dropzoneControls .dropzoneHover.dropzoneHoverShow:hover{
  opacity: 1;
  transition: 0.3s all ease;
}

.dropzoneHover p{
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
}

.dropzoneTitle {
  font-weight: 500;
  font-size: 14px;
  color: var(--doveGrayS);
}

.titleInpWrap {
  margin-top: 16px;
}

.titleInpWrap input {
  padding: 16px;
}

.itemTitle {
  font-weight: 600;
  font-size: 16px;
  color: var(--mineShaftS);
}

.checkboxes {
  margin-top: 16px;
}

.copies {
  margin-top: 24px;
}

.inputLabel {
  font-weight: 500;
  font-size: 14px;
  color: var(--doveGrayS);
}

.inputLabel span {
  font-weight: 700;
}

.inputSubText {
  font-weight: 500;
  font-size: 14px;
  margin-top: 4px;
  margin-left: 12px;
  color: var(--doveGrayS);
}

.categories {
  margin-top: 24px;
}

.checkboxes {

}

.uploadForm {
  min-height: 157px;
}

.imageDefault {
  height: 157px;
}

.checkboxes .priceTyleLabel {
  padding: 36px;
  border: 1px solid var(--doveGrayS);
  border-radius: 4px;
  color: var(--doveGrayS);;
  height: 120px;
  min-width: 164px;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.checkItem {
  width: 100%;
}

.priceTypeInput:checked ~ .priceTyleLabel {
  border: 1px solid var(--pirateCold);
  color: var(--pirateCold);
}

.icon {
  width: 20px;
  height: 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.fixedPriceIcon {
  background-image: url('../../assets/icons/fixedPriceIcon.svg');
}

.priceTypeInput:checked ~ .priceTyleLabel .fixedPriceIcon {
  background-image: url('../../assets/icons/fixedPriceIcon-active.svg');
}

.timedAuctionIcon {
  background-image: url('../../assets/icons/timedAuction.svg');
}

.priceTypeInput:checked ~ .priceTyleLabel .timedAuctionIcon {
  background-image: url('../../assets/icons/timedAuction-active.svg');
}

.unlimitedAuctionIcon {
  background-image: url('../../assets/icons/unlimitedAuction.svg');
}

.priceTypeInput:checked ~ .priceTyleLabel .unlimitedAuctionIcon {
  background-image: url('../../assets/icons/unlimitedAuction-active.svg');
}

.selectStyle {
  background: var(--alabasterapprox);
  cursor: pointer;
}

.dateField {
  background-color: var(--wildSand);
}

.preview {
  pointer-events: none;
}

.preview img {
  object-fit: contain !important;
}

.iFrameStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  object-fit: contain;
}

.iFrameStyle img {
  object-fit: contain;
}

.spinnerWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

/*
.titleInpWrap input{
    margin-top: 0;
}*/

.errorMessage, .dateError p{
  margin-top: 10px;
  color: var(--thunderbird);
  font-size: 16px;
}

.datePickWrap input{
  background-color: var(--wildSand);
}

.dateInput {
  padding: 0 12px;
  width: 100%;
  min-height: 60px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  outline: none;
  border: none;
  background-color: var(--wildSand);
}

.rangeWrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.rangeInvalid * {
  color: var(--thunderbird) !important;
}

@media (max-width: 991px) {
  .formChecked {
    flex-direction: column;
    padding: 0 20px;
  }

  .container {
    padding: 10px;
  }

  .checkItem, .checkItem label {
    width: 100%;
  }
}

@media(max-width: 400px) {
  .rangeWrap {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }

  .mobileColumn {
    flex-direction: column;
  }
}

.errorWrap{

}
.errorWrap p{
  margin: 6px 0 0 14px;
  font-weight: 500;
  font-size: 12px;
}
