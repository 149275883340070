.customDropdown {
}
.dropdownSelect,
.dropdownSelectFilter {
  background-color: var(--white);
  border: 1px solid var(--pirateCold);
  color: var(--pirateCold);
  text-transform: uppercase;
  border-radius: 30px;
  font-size: 14px;
}

.dropdownSelectFilter {
  border-radius: 4px;
}

.dropdownButton.isHiddenArrow:after {
  display: none;
}

.dropdownButton:hover {
  color: var(--pirateCold);
}

.customDropdown .dropdownSelectFilter:hover,
.customDropdown .dropdownSelectFilter:focus,
.customDropdown .dropdownSelect:hover,
.customDropdown .dropdownSelect:focus {
  background-color: rgba(174, 126, 3, 0.2);
  text-transform: uppercase;
  box-shadow: none;
}
.dropdownItem {
}
.dropdownItem:active,
.dropdownItem:focus {
  background-color: var(--linkWater);
  color: var(--denim);
}

.dropdownSelectGray {
  background-color: var(--wildSand);
}
.userDropdownButton {
  display: flex;
  align-items: center;
  color: var(--mineShaftS);
  font-size: 14px;
}
.userDropdownButton img {
  height: 24px;
  width: 24px;
  margin-right: 8px;
}
/*.userDropdown {
  margin-right: 8px;
}*/

.profileName {
  font-size: 20px;
}

.avatar {
  margin-bottom: 10px;
  border-radius: 100%;
  object-fit: cover;
}

.idTextStyle {
  color: var(--doveGrayS);
}

.line {
  margin: 16px 0;
  width: 100%;
  height: 1px;
  background-color: rgba(174, 126, 3, 0.2);
}

.navList {
  padding: 0 10px;
  width: 100%;
}

.navList li a {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: var(--mineShaftS);
}

.navList li a img {
  margin-right: 16px;
}

.navList li {
  padding: 10px 0;
}

.userAvatar {
  border-radius: 100%;
  object-fit: cover;
}
