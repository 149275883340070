.inputMenu:checked + .label div {
  top: 10px;
  transform: rotate(45deg);
  transition: top 0.15s, transform 0.12s 0.15s;
}

.inputMenu:checked + .label div:nth-child(3) {
  transform: rotate(135deg);
  transition: top 0.15s, transform 0.3s 0.15s;
}

.label {
  position: relative;
  width: 30px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.5s;
  text-align: left;
}

.label div,
.label div:before,
.label div:after {
  background: var(--pirateCold);
  position: absolute;
  height: 3px;
  width: 30px;
  border-radius: 4px;
  transition: all 0.5s;
}

.label div:first-child {
  top: 0px;
}

.label div:nth-child(2) {
  top: 8px;
}

.label div:nth-child(3) {
  top: 16px;
}

.menuWrap {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100vh;
  min-width: 100vw;
  background: rgba(18, 18, 18, 0.8);
  z-index: 11;
}

.menuWrap.open {
  display: block;
}

.menu {
  position: absolute;
  top: 0;
  left: -100%;
  min-width: 320px;
  min-height: 100vh;
  background: var(--white);
  transition: all 0.4s ease;
  overflow-y: auto;
  max-height: 100vh;
  z-index: 12;
}

.menu.open {
  left: 0;
}

.btnClose {
  background-color: transparent;
  border: none;
}

.navWrap nav ul {
  flex-direction: column;
  align-items: flex-start !important;
}

.navWrap nav ul li {
  margin: 0 0 15px 0;
}

.avatar {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 100%;
  object-fit: cover;
}

.name {
  font-weight: 500;
  font-size: 16px;
  color: var(--mineShaftS);
}

.accountId {
  font-weight: 400;
  font-size: 14px;
  color: var(--doveGrayS);
}

.otherNavigation {
  margin: 15px 0;
  width: 100%;
}

.otherNavigation li a {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: var(--mineShaftS);
}

.otherNavigation li a img {
  margin-right: 16px;
}

.otherNavigation li {
  padding: 10px 0;
}

.buttonDisconnect {
  margin-top: 20px;
  width: 100%;
}
