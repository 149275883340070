.cardWrap {
  position: relative;
  width: 100%;
  max-width: 290px;
  background: var(--white);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.05), 0 30px 50px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
}

.coverImage {
  width: 100%;
  height: 100%;
  min-height: 136px;
  max-height: 136px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 4px 4px 30px 30px;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.imageWrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -40px auto 0 auto;
  width: 88px;
  height: 88px;
  background-color: #C4C4C4;
  border-radius: 100%;
  border: 6px solid var(--white);
  z-index: 1;
}

.content {
  margin-top: 10px;
  padding: 16px;
}

.collectionName {
  font-weight: 500;
  font-size: 16px;
  color: var(--mineShaftS);
}

.creatorName {
  font-weight: 500;
  font-size: 14px;
  color: var(--doveGrayS);
}

.actionsWrap {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
}

.btnAction {
  display: block;
  background-color: var(--white);
  padding: 10px;
  width: 36px;
  height: 36px;
  border: 1px solid rgba(174, 126, 3, 0.2);
  border-radius: 4px;
  font-family: inherit;
}

.preview .btnAction {
  pointer-events: none;
  cursor: default !important;
}

.modalPreviewCover {
  width: 100%;
  height: 100%;
  min-height: 320px;
  max-height: 320px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 0 50px 50px;
  background-color: #14141F;
  background-position: center center;
}

.modalPreviewLogoWrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -106px auto 25px auto;
  width: 212px;
  height: 212px;
  background-color: #C4C4C4;
  border-radius: 100%;
  border: 6px solid var(--white);
  z-index: 1;
}

.modalPreviewLogo {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.modalPreviewName {
  font-family: 'Bebas Neue', "Urbanist", sans-serif;
  margin-bottom: 30px;
  padding: 0 46px;
  font-weight: 700;
  font-size: 48px;
  text-align: center;
  color: var(--black);
  text-transform: uppercase;
}

.modalPreviewDescription {
  margin-bottom: 40px;
  padding: 0 46px;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: var(--steelGray);
}

.cardWrapBig {
  position: relative;
  width: 100%;
  max-width: 690px;
  background: var(--white);
  border-radius: 4px;
  border-bottom: 1px solid #EBEBEB;
  transition: all 0.4s ease;
}

.cardWrapBig:hover {
  box-shadow: 0 20px 15px rgba(174, 126, 3, 0.05);
}

.cardWrapBig .coverImage {
  min-height: 186px;
  max-height: 186px;
  border-radius: 4px 4px 30px 30px;
}

.cardWrapBig .imageWrap {
  margin: -65px auto 0 auto;
  width: 130px;
  height: 130px;
}

.cardWrapBig .collectionName {
  margin-bottom: 20px;
  font-family: 'Bebas Neue', 'Urbanist', sans-serif;
  font-size: 48px;
  text-align: center;
  text-transform: uppercase;
}

.cardWrapBig .description {
  margin-bottom: 70px;
  font-family: 'Urbanist', sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #1F1F2C;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 500px) {
  .cardWrapBig .collectionName {
    font-size: 28px;
  }

  .cardWrapBig .description {
    margin-bottom: 30px;
    font-size: 14px;
  }

  .controls {
    flex-direction: column;
    gap: 20px;
  }
}
