.timerWrap {
  display: flex;
  align-items: center;
  gap: 12px;
}

.timerWrap p {
  min-width: 52px;
  padding: 12px 10px;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  color: var(--pirateCold);
  border: 2px solid var(--pirateCold);
  border-radius: 6px;
}

.timerWrap span {
  font-size: 30px;
  font-weight: 700;
  color: var(--pirateCold);
}

.timerWrapSmall {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  min-width: 150px;
  background: rgba(255,255,255,0.7);
  border: 1px solid var(--altoS);
  backdrop-filter: blur(4px);
  border-radius: 13px;
}

.numWrapSm {
  display: flex;
  align-items: center;
  min-width: 90px;
  gap: 1px;
  font-weight: 700;
  font-size: 14px;
  color: var(--steelGray);
}

.timerWrapSmall img {
  margin-right: 12px;
}

@media (max-width: 400px) {
  .timerWrap {
    gap: 8px;
  }

  .timerWrap p {
    min-width: 48px;
    padding: 8px 6px;
    font-size: 16px;
  }
}
