
.container {
  text-align: center;
}
.clock {
  margin-bottom: 28px;
}
.image {
  display: block;
  margin: 0 auto;
}
.title {
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  color: var(--mineShaftS);
}
.description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--doveGrayS);
}
.notes {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--doveGrayS);
}

.progressbar {
  margin: 20px 0;
}
