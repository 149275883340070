.filterWrap {
  display: flex;
  align-items: center;
  gap: 15px;
}

.datePWrap {
  width: 100%;
  max-width: 200px;
  overflow: hidden;
}

.datePWrap input {
  width: 100%;
}

.dateInput {
  padding: 0 12px;
  min-height: 60px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  outline: none;
  border: none;
  background-color: var(--wildSand);
}

.separator {
  width: 1px;
  height: 40px;
  background-color: var(--mercury);
  margin: 0 5px;
}

.select {
  min-width: 200px;
}

.resultWrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.clearButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
}

.resultItem {
  padding: 6px 12px;
  background: rgba(174, 126, 3, 0.2);
  border-radius: 4px;
  color: var(--mineShaftS);
}

.resultItem img {
  margin-left: 5px;
}

@media (max-width: 1200px) {
  .filterWrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .datePWrap {
    max-width: unset;
  }
}

@media (max-width: 768px) {
  .filterWrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 560px) {
  .filterWrap {
    flex-direction: column;
  }

  .select,
  .inputView {
    width: 100%;
  }

  .separator {
    display: none;
  }
}

@media (max-width: 440px) {
  .filterWrap {
    grid-template-columns: repeat(1, 1fr);
  }

  .resultWrap {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
