.filterWrap ul {
  background-color: transparent;
}

.inputSearch {
  width: 100%;
  max-width: 360px;
}

.scrollWrap {
  flex-wrap: wrap;
}

@media(max-width: 600px) {
  .scrollWrap {
    overflow-x: auto;
    flex-wrap: nowrap;
  }
}
