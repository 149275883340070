.listWrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}

@media (max-width: 992px) {
  .listWrap {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 500px) {
  .title {
    font-size: 38px;
  }
}
