.modalBtn {
  padding: 12px 26px !important;
}

.inputSubText {
  font-weight: 500;
  font-size: 14px;
  margin-top: 4px;
  margin-left: 12px;
  color: var(--doveGrayS);
}
.viewCard{
  width: auto !important;
  border: none !important;
}

.price{
  font-weight: 600;
  font-size: 14px;
  color: var(--pirateCold);
  margin-bottom: 12px;
}
.line {
  margin: 16px 0;
  width: 100%;
  height: 1px;
  background-color: rgba(174, 126, 3, 0.2);
}
.totalWrap{

}
.totalTitle{
  font-weight: 500;
  font-size: 14px;
  color: var(--doveGrayS);
}
.total{
  font-weight: 600;
  font-size: 16px;
  color: var(--mineShaftS);
}

.errorBox p{
  margin-top: 10px;
  color: var(--thunderbird);
  font-size: 16px;
}
