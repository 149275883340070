.header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  padding: 15px 24px;
  border-bottom: 1px solid var(--mercury);
  background-color: var(--white);
  z-index: 10;
}

.buttonWrap button:not(:last-of-type) {
  margin-right: 16px;
}

.separator {
  width: 1px;
  height: 40px;
  background-color: var(--mercury);
  margin: 0 15px;
}

.logo {
  margin-right: 40px;
}

.linkCreate {
  margin-left: 14px;
}

.inputSearchWrap {
  max-width: 360px;
  width: 100%;
}

@media (max-width: 1200px) {
  .inputSearchWrap {
    width: 200px;
  }
}
