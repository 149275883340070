.artistCard {
  font-family: 'Urbanist', sans-serif;
  letter-spacing: 0.04rem;
  padding: 16px;
  width: 100%;
  max-width: 290px;
  background: var(--alabaster);
  border: 1px solid var(--artistBorder);
  border-radius: 4px;
}

.artistWrap {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.infoStyle {
  padding: 12px 16px;
  margin-bottom: 0;
  width: 100%;
  background: #F8F8F8;
  border-radius: 16px;
}

.infoTitle {
  font-weight: 400;
  font-size: 13px;
  color: var(--manatee);
}

.artistName {
  margin: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  color: var(--black);

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 99%;
  white-space: nowrap;
}

.artistAvatar {
  margin-right: 16px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 100%;
  object-fit: cover;
}

.userInfo {
  margin-right: 20px;
}

.buttonFollow {
  padding: 6px 12px !important;
}

.likesCustom {
  margin-right: 10px;
}

.pointerNone {
  pointer-events: none;
}

@media (max-width: 767px) {
  .artistCard {
    margin: 0 auto;
    max-width: 90%;
  }
}

.cardWrap {
  position: relative;
  width: 100%;
  max-width: 310px;
  background: var(--white);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.05), 0 30px 50px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
}

.coverImage {
  width: 100%;
  height: 100%;
  min-height: 136px;
  max-height: 136px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 4px 4px 30px 30px;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.imageWrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -40px auto 0 auto;
  width: 88px;
  height: 88px;
  background-color: #C4C4C4;
  border-radius: 100%;
  border: 6px solid var(--white);
  z-index: 1;
}

.content {
  margin-top: 10px;
  padding: 16px;
}

.creatorName {
  font-weight: 500;
  font-size: 14px;
  color: var(--doveGrayS);
}

.actionsWrap {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
}

.btnAction {
  display: block;
  background-color: var(--white);
  padding: 10px;
  width: 36px;
  height: 36px;
  border: 1px solid rgba(174, 126, 3, 0.2);
  border-radius: 4px;
  font-family: inherit;
}

.preview .btnAction {
  pointer-events: none;
  cursor: default !important;
}

@media (max-width: 500px) {
  .cardWrap {
    min-width: 310px;
  }
}
