.tokenWrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 70px;
}

@media (max-width: 1200px) {
  .tokenWrap {
    display: flex;
    gap: 20px;
    overflow: auto;
    padding: 10px;
  }
}
